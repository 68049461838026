@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


































































































































































.video-info {
  background: #fff;
  padding: 16px;
  padding-bottom: 0;
  .user {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-right: 8px;
      display: flex;
    }
    .name {
      height: 19px;
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      line-height: 19px;
    }
    .other {
      height: 15px;
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 15px;
    }
    .btn {
      width: 56px;
      height: 28px;
      background: #edf5fc;
      border-radius: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #1383eb;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
    }
    .focused-btn {
      width: 56px;
      height: 28px;
      background: #f5f5f5;
      border-radius: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 28px;
      text-align: center;
    }
  }
  .title {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .desc {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    position: relative;
    .all-btn {
      font-size: 13px;
      font-weight: 400;
      color: #2496ff;
      line-height: 17px;
      z-index: 10;
      position: absolute;
      bottom: 0;
      right: 6px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .original-tag {
      width: 28px;
      line-height: 14px;
      border-radius: 2px;
      border: 1px solid rgba(36, 150, 255, 0.4);
      font-size: 10px;
      font-weight: 400;
      color: #1383eb;
      text-align: center;
      padding: 1px 4px 0;
    }
    .act {
      display: flex;
      align-items: center;
      margin-left: 8px;
      .text {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #ff925c;
        line-height: 17px;
        margin-left: 4px;
        padding-top: 1px;
      }
    }
  }

  .game-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    height: 72px;
    .bbs {
      display: flex;
      align-items: center;
      max-width: 50vw;
      .bbs-icon {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 7px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 8px;
        cursor: pointer;
      }
      .info {
        .game-name {
          max-width: 150px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          margin-bottom: 2px;
          @include ellipsis();
        }
        .links {
          display: flex;
          img {
            margin-left: 2px;
            margin-right: 12px;
            padding-top: 2px;
          }
          a {
            font-size: 10px;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.6);
            line-height: 15px;
            display: flex;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .btn {
        width: 68px;
        height: 32px;
        background: #f5f5f5;
        border-radius: 16px;
        font-size: 12px;
        font-weight: 400;
        color: $TextColor3rd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          margin-left: 4px;
        }
      }
      .btn:nth-child(1) {
        margin-right: 16px;
      }
    }
  }
}
