@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































































.halo-image {
  position: relative;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: 100%;
  }
  .load {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
  }

  .slot {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
