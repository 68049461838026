@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.version-list {
  margin: 0 15px 28px;
  background: #fff;
  border-radius: 4px;
  padding: 12px 13px;
  .version-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
    .version-icon {
      width: 64px;
      height: 64px;
      border-radius: 14px;
      display: flex;
      margin-right: 11px;
      overflow: hidden;
    }
    .name {
      font-size: 13px;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
      margin-bottom: 9px;
    }
    .tags {
      display: flex;
      .tag {
        height: 14px;
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        padding: 0 4px;
        margin-right: 7px;
        background: #f6f6f6;
        border-radius: 1px;
        padding-top: 2px;
      }
    }
  }
}
