@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}

.search-list {
  background: #fff;
  padding: 0 15px;
  .search-item {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info {
      display: flex;
      .game-icon {
        width: 60px;
        height: 60px;
        border-radius: 13px;
        overflow: hidden;
        margin-right: 11px;
      }
      .game-msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .game-title {
        max-width: 170px;
        margin-bottom: 6px;
        height: 19px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #323334;
        line-height: 19px;
        @include ellipsis();
      }
      .desc {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        max-width: 50vw;
        @include ellipsis();
      }
    }
    .btn {
      width: 52px;
      height: 26px;
      background: linear-gradient(
        90deg,
        #4bc7ff 0%,
        #2496ff 100%,
        #2496ff 100%
      );
      border-radius: 13px;
      font-size: 11px;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
    }
  }
  .search-item:last-child {
    margin-bottom: 0;
  }
}
.empty-area {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}
