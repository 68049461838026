@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
::v-deep .van-popup {
  width: 300px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.title-wrapper {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.list {
  max-height: 352px;
  overflow-y: auto;
  .item {
    height: 80px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .avatar {
      width: 48px;
      height: 48px;
      border-color: rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 16px;
    }
    .msg {
      max-width: calc(100% - 70px);
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      color: $TextColor1st;
    }
    .des {
      width: 48vw;
      font-size: 12px;
      font-weight: 400;
      color: $TextColor3rd;
      margin-top: 8px;
      max-width: 100%;
      @include ellipsis();
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
