@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




































































::v-deep .van-popup {
  border-radius: 10px 10px 0px 0px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    padding: 4px 0;
    height: 23px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  .list {
    width: 100%;
    max-height: 306px;
    overflow-y: auto;
  }
  .item {
    width: 100%;
    padding: 16px 0 14px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .tag {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
}
element::-webkit-scrollbar {
  display: none;
}
