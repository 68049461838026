@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































.novel-column {
  max-height: 174px;
  padding: 16px 14px;
  box-sizing: border-box;
  background: #fff;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .title {
      height: 25px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      max-width: 218px;
      @include ellipsis();
    }
    .btn {
      display: flex;
      align-items: top;
    }
    .btn-text {
      font-size: 13px;
      font-weight: 400;
      color: #2496ff;
      line-height: 16px;
    }
    .btn-more {
      padding-top: 1px;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
      width: 64px;
      .image {
        width: 64px;
        height: 64px;
        border-radius: 14px;
        margin-bottom: 8px;
        overflow: hidden;
        font-size: 12px;
      }
      .game-name {
        max-width: 60px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        @include ellipsiss();
      }
    }
  }
}
