@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































































.video-item {
  width: 242px;
  height: 136px;
  background-color: rgb(240, 168, 168);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  &.fullsreen {
    height: 100vh;
  }
  .inner-item {
    width: 100%;
    background: white;
    height: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .app-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 136px;
    .poster-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      img.poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .start-player {
        width: 47px;
        height: 47px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: rgba(115, 133, 159, 0.5);
        z-index: 11;
        background: url(~@/assets/images/icon_play.png) center no-repeat;
        background-size: 100% 100%;
      }
      .info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 242px;
        height: 32px;
        padding: 0 11px;
        box-sizing: border-box;
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 9px;
        font-weight: 400;
        color: #ffffff;

        .vote {
          display: flex;
          align-items: center;
        }
        .vote-icon {
          margin-right: 4px;
          width: 11px;
          height: 10px;
          display: flex;
        }
      }
    }
  }
}
.title {
  width: 235px;
  height: 19px;
  font-size: 13px;
  font-weight: bold;
  color: #333333;
  line-height: 19px;
  margin-top: 7px;
}
