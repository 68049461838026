@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































































































































































































.game-list-container {
  background: #f5f5f5;
  padding-top: 13px;
  padding-bottom: 15px;
}
.game-list {
  box-sizing: border-box;
  padding: 0 15px;
}
