@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































































































































































































::v-deep .van-tab:first-child {
  margin-left: 9px;
}
::v-deep .van-tabs--line {
  .van-tabs__wrap {
    height: 45px;
  }
  .van-tabs__line {
    width: 20px !important;
  }
  .van-tab {
    max-width: 58px;
    font-size: 14px;
  }
  .van-hairline--top-bottom::after {
    display: none;
  }
}
::v-deep .van-tab--active .van-tab__text {
  color: #2496ff;
}
.page-content {
  font-size: 28px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 17px 10px;
    background: #fff;
    .left {
      display: flex;
      align-items: center;
      .name {
        max-width: 250px;
        margin-left: 14px;
        height: 24px;
        font-size: 17px;
        font-weight: 500;
        color: #000000;
        line-height: 26px;
        opacity: 0.9;
        @include ellipsis();
      }
    }
    .search-icon {
      width: 18px;
      height: 18px;
    }
  }
  .divider {
    width: 100%;
    height: 7px;
    background: #f5f5f5;
  }

  .backtop {
    position: fixed;
    right: 7px;
    bottom: 7px;
    z-index: 50;
  }
}
