@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































.article-list-card {
  background-color: #fff;
  width: auto;
  margin: 16px;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  .title {
    height: 54px;
    line-height: 54px;
    box-sizing: border-box;
    color: #333333;
    padding: 0 14px;
    border-bottom: 1px solid #eee;
    font-size: 16px;
    font-weight: bold;
  }
  .content {
    padding: 16px 14px 1px;
    & > li {
      margin-bottom: 16px;
    }
  }
}
