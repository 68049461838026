@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.game-card {
  width: 100%;
  height: 270px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  overflow: hidden;
  display: block;
  .banner {
    width: 100%;
    height: 179px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .info {
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    .name {
      max-width: 218px;
      height: 23px;
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
      margin-right: 6px;
      @include ellipsis();
    }
    .tags {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }
    .tag {
      height: 16px;
      line-height: 16px;
      background: #ffffff;
      font-size: 11px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-right: 8px;
      padding: 0px 4px;
      padding-top: 2px;
      &:after {
        border-radius: 8px;
        border-color: #ccc;
      }
    }

    .desc {
      max-width: 217px;
      @include ellipsis();
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 15px;
      margin-top: 6px;
    }
    .star {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
    }
    .score {
      font-size: 22px;
      font-weight: bold;
      color: #2496ff;
      margin-left: 8px;
      height: 32px;
      line-height: 34px;
      padding-top: 1px;
    }
  }
}
