@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































.top-video {
  width: 100%;
  height: 197px;
  background-color: rgb(240, 168, 168);
  display: flex;
  justify-content: center;
  align-items: center;
  &.fullsreen {
    height: 100vh;
  }
  .inner-item {
    width: 100%;
    background: white;
    height: 197px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .app-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 197px;
    .poster-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      img.poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .start-player {
        width: 47px;
        height: 47px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: rgba(115, 133, 159, 0.5);
        z-index: 11;
        background: url(~@/assets/images/icon_play.png) center no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
