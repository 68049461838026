@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















.load-more-btn {
  height: 41px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  font-size: 14px;
  font-weight: 400;
  color: #2496ff;
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}
