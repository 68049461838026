@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































































































































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-column {
  ::v-deep strong {
    font-weight: bold;
  }
  ::v-deep em {
    font-style: italic;
  }
  ::v-deep a {
    text-decoration: underline;
  }
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .name-icon {
        width: 14px;
        height: 14px;
        display: flex;
      }
      .name {
        height: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        margin-left: 4px;
        margin-right: 7px;
      }
      .name-link {
        height: 15px;
        font-size: 11px;
        font-weight: 400;
        color: #2496ff;
        line-height: 15px;
        text-decoration: underline;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .right-text {
        height: 15px;
        font-size: 11px;
        font-weight: 400;
        color: #2496ff;
        line-height: 15px;
      }
      .right-icon {
        width: 11px;
        height: 11px;
        display: flex;
      }
    }
  }
  .text {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    margin: 7px 0;

    ::v-deep p {
      line-height: 18px;
    }

    position: relative;
    .all-btn {
      font-size: 12px;
      font-weight: 400;
      color: #2496ff;
      line-height: 17px;
      z-index: 10;
      position: absolute;
      bottom: 0;
      right: 0px;
      background: #fff;
    }
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tag {
      display: flex;
      align-items: center;
      margin-right: 22px;
      img {
        margin-right: 4px;
      }
      .tag-text {
        font-size: 11px;
        font-weight: 400;
        color: #2496ff;
        line-height: 16px;
      }
    }
  }
}
