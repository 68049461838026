@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";







































































::v-deep .van-popup {
  border-radius: 10px 10px 0px 0px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  textarea {
    color: #666;
    border: none;
  }
  textarea::placeholder,
  textarea::-ms-input-placeholder {
    color: #cccccc;
  }

  .btn {
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    background: #2496ff;
    padding: 0 16px;
  }
  .disabled.btn {
    background: #f5f5f5;
    color: #ccc;
  }
}
element::-webkit-scrollbar {
  display: none;
}
