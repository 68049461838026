@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























.card {
  padding-top: 14px;
  padding-left: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f6f6f6;
  background-color: #fff;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
  }
  .right {
    width: 180px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px 0 8px;
    margin-right: 24px;
    .search-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .clear-icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    input {
      margin-left: 8px;
      flex: 1;
      border: none;
      background-color: transparent;
      padding: 0;
      width: 112px;
      font-size: 12px;
      font-weight: 400;
      color: $TextColor1st;
      line-height: 17px;
    }

    input::-webkit-input-placeholder {
      color: $TextColor;
    }

    .clear-btn {
      font-size: 16px;
      font-weight: 400;
      color: #2496ff;
      line-height: 22px;
      position: absolute;
      right: 0;
      top: 3px;
      cursor: pointer;
    }
  }
}
