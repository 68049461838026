@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































































html {
  font-size: 14px;
  min-width: 320px;
  overflow: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Microsoft YaHei,
    PingFang SC, miui, -apple-system, BlinkMacSystemFont, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}
