@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




























































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
::v-deep .van-popup {
  width: 300px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.inner-wrap {
  padding: 24px;
  box-sizing: border-box;
  position: relative;
}
.close-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
}
.msg {
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    width: 37px;
    height: 37px;
    border-radius: 9px;
    margin-right: 9px;
    overflow: hidden;
  }
}
.msg-text {
  max-width: 180px;
  @include ellipsis();
  .name {
    height: 19px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .version {
    height: 12px;
    font-size: 8px;
    font-weight: 400;
    color: #666666;
    line-height: 12px;
  }
}
.tip {
  height: 13px;
  font-size: 9px;
  font-weight: bold;
  color: #333333;
  line-height: 13px;
  margin: 14px 0 16px;
}
.list {
  max-height: 352px;
  overflow-y: auto;
  .item {
    margin-bottom: 16px;
    font-size: 8px;
    font-weight: bold;
    color: #333333;
    line-height: 12px;
  }
  .inner-item {
    font-size: 8px;
    font-weight: 400;
    color: #666666;
    line-height: 12px;
    margin-top: 9px;
  }
}
element::-webkit-scrollbar {
  display: none;
}
