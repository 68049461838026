@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





















































































































































::v-deep .van-popup {
  width: 320px;
  height: 168px;
  border-radius: 4px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.title-wrapper {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $TextColor1st;
  }
  .close-icon {
    width: 16px;
    height: 16px;
  }
}
.shares {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px 0;
  .share-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 96px;
  }
  .share-title {
    font-size: 12px;
    font-weight: 400;
    color: $TextColor1st;
    margin-top: 9px;
  }
}
element::-webkit-scrollbar {
  display: none;
}
