@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";







































.choice-item {
  font-size: 14px;
  padding: 16px;
  border-bottom: 1px solid #eeeeee;
  .title {
    max-width: 632px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 4px;
  }
  .content {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .imgs {
    display: flex;
    gap: 12px;
    margin-top: 20px;
    .img {
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  .time {
    margin-top: 22px;
    font-size: 12px;
    font-weight: 400;
    color: #cccccc;
    line-height: 12px;
  }
}
