@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
::v-deep .van-field__clear {
  color: rgb(220, 220, 220);
}
::v-deep .van-cell:not(:last-child)::after {
  border-bottom: none;
}
.search-container {
  padding: 6px 0;
  .search-top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    .cancel-btn {
      width: 28px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 20px;
      white-space: nowrap;
      margin-left: 12px;
    }
    .input {
      background: #f4f5f6;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 18px;
      padding: 7px 12px;
      height: 32px;
      ::v-deep input {
        padding-top: 1px;
      }
      ::-webkit-input-placeholder {
        color: #ccc;
      }
      :-moz-placeholder {
        color: #ccc;
      }
      ::-moz-placeholder {
        color: #ccc;
      }
      :-ms-input-placeholder {
        color: #ccc;
      }
      ::-ms-input-placeholder {
        color: #ccc;
      }
      ::placeholder {
        color: #ccc;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 19px;
    padding-bottom: 24px;
    .item {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        margin-right: 12px;
        overflow: hidden;
        padding: 14px 0;
      }
      .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .name {
        max-width: 182px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 3px;
        @include ellipsis();
      }
      .desc {
        width: 276px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        @include ellipsis();
      }
    }
  }

  .empty-area {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
}
