@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































.novel-hot {
  max-height: 203px;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  .title-row {
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    margin-top: 16px;
    margin-left: 14px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 14px 0;
    margin-top: 0px;

    .item {
      height: 36px;
      background: #f0f4f7;
      border-radius: 20px;
      margin-right: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #7690ad;
      display: flex;
      align-items: center;
      padding-left: 4px;
      padding-right: 12px;
      margin-bottom: 16px;
      .icon {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        overflow: hidden;
        margin-right: 8px;
      }
      .text {
        max-width: 275px;
        @include ellipsis();
      }
    }
  }
}
