@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




























































::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 14px;
}
::v-deep .van-tab--active {
  color: #2496ff;
}
::v-deep .van-tabs__wrap {
  width: 120px;
  height: 42px;
}
.search-result-container {
  margin-top: 6px;
  padding-bottom: 15px;
  .divider {
    width: 100%;
    height: 7px;
    background: #f5f5f5;
    margin-top: 1px;
    margin-bottom: 15px;
  }
}
