@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































































































































































































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.c-article-container {
  position: relative;
}
.header {
  width: 100vw;
  min-width: 320px;
  height: 56px;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: $TextColor1st;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  h1 {
    margin: 0 16px;
    @include ellipsis();
  }
  .left {
    display: flex;
    align-items: center;
    max-width: calc(100% - 24px);
  }
}
h2 {
  margin: 20px 16px 12px;
  font-size: 20px;
  font-weight: bold;
  color: $TextColor1st;
  line-height: 28px;
}
.labels {
  display: flex;
  margin-bottom: 28px;
  margin-left: 16px;
}
.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
  .left {
    display: flex;
    align-items: center;
    .avatar-wrapper {
      position: relative;
      width: 32px;
      height: 32px;
      margin-right: 12px;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        box-sizing: border-box;
      }
      .auth-icon {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .name {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      color: $TextColor1st;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: $TextColor3rd;
    }
  }
  .btn {
    width: 56px;
    height: 28px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid $DividerColor;
    font-size: 12px;
    font-weight: 400;
    color: $TextColor2nd;
    line-height: 28px;
    text-align: center;
  }
}
.content {
  padding: 16px;
}
.other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 16px;
  .bbs {
    display: flex;
    align-items: center;
    .bbs-icon {
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 7px;
      margin-right: 8px;
    }
    .info {
      .game-name {
        max-width: 140px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        @include ellipsis();
        margin-bottom: 2px;
      }
      .links {
        display: flex;
        white-space: nowrap;
        img {
          margin-left: 2px;
          margin-right: 12px;
          padding-top: 2px;
        }
        a {
          font-size: 10px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6);
          line-height: 17px;
          display: flex;
        }
      }
    }
    @media screen and (max-width: 360px) {
      .game-name {
        max-width: 23vw;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    .btn {
      width: 68px;
      height: 32px;
      background: #f5f5f5;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 400;
      color: $TextColor3rd;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 4px;
      }
    }
    .btn:nth-child(1) {
      margin-right: 12px;
    }
  }
}
.divider {
  width: 100vw;
  height: 8px;
  background: $BgColor;
}
.fixed-bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
