@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
















































.video-recommends {
  border-radius: 4px;
  background: #fff;
  .title {
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    padding-top: 16px;
    padding-left: 16px;
  }
  .list {
    padding: 12px 0;
    .item {
      display: flex;
      padding: 4px 16px;
      margin-bottom: 16px;
    }
    .poster {
      width: 120px;
      height: 68px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 12px;
      position: relative;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
    .length {
      height: 14px;
      line-height: 14px;
      text-align: center;
      padding: 0 2px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 2px;
      font-size: 10px;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      right: 2px;
      bottom: 2px;
      z-index: 5;
    }
    .msg-title {
      max-width: calc(100vw - 170px);
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      @include ellipsis();
    }

    .name {
      max-width: calc(100vw - 170px);
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
      margin: 4px 0 10px;
      @include ellipsis();
    }
    .other {
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 15px;
    }
  }
}
