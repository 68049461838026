@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































































































































::v-deep .van-tabbar-item__icon img {
  height: 100%;
}
::v-deep .van-tabbar-item__text {
  font-size: 9px;
}
.home-container {
  position: relative;
}

.home-main {
  overflow-y: auto;
}
.fixed-bottom {
  position: absolute;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  .row-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .backtop {
      position: absolute;
      right: 7px;
      bottom: 7px;
      z-index: 50;
    }
  }
}
