@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.des-container {
  background: #fff;

  .des-title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    .title-text {
      height: 20px;
      line-height: 20px;
      padding-top: 1px;
    }
  }
  .des-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    padding-bottom: 2px;
  }
  .des-text {
    margin-top: 7px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    position: relative;
    .all-btn {
      font-size: 12px;
      font-weight: 400;
      color: #2496ff;
      line-height: 17px;
      z-index: 10;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #fff;
    }
  }
}
