@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";























































































































.footer-component {
  overflow: hidden;
  box-sizing: border-box;
  width: 100vw;
  min-height: 120px;
  background: #2c3a4d;
  padding: 16px 8px 0 16px;
  .links-wrapper {
    .link-title {
      height: 23px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 23px;
    }
    .links {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
    }
    .link-item,
    .link-item a {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 17px;
      margin-right: 16px;
      margin-bottom: 8px;
      display: inline-block;
    }
    .link-other {
      display: flex;
    }
  }
  .main {
    .title {
      margin-top: 24px;
      width: 68px;
      height: 16px;
    }

    .flex {
      display: flex;
      align-items: flex-end;
      margin: 12px 0 24px 0;

      .desc {
        flex: 1;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 12px;

          a:nth-child(1) {
            margin-right: 24px;
          }
          a {
            color: rgba(255, 255, 255, 0.6);
          }
        }

        p:nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
