@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



































.loading-wrapper {
  padding: 16px;
}
.container {
  width: 100%;
  height: 40px;
  background: $BgColor;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: $TextColor3rd !important;
  line-height: 12px !important;
  span {
    margin-left: 10px;
  }
}
