@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


























































































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

::v-deep .van-divider {
  margin: 11px 0;
  border-color: $DividerColor;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding-top: 1px;
  }
  .count {
    font-size: 13px;
  }
}
.comment-list {
  margin: 0 15px 28px;
  background: #ffffff;
  border-radius: 4px;
  padding: 15px 13px 0;
  .comment-item {
    .comment-top {
      display: flex;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        margin-right: 6px;
      }
      .name {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-bottom: 3px;
      }
      .other {
        display: flex;
      }
      .stars {
        display: flex;
        .star {
          width: 11px;
          height: 11px;
          margin-right: 2px;
          display: flex;
        }
      }
      .timeago {
        margin-left: 5px;
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-bottom: 11px;
      }
    }

    .text {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      position: relative;
      .all-btn {
        font-size: 12px;
        font-weight: 400;
        color: #2496ff;
        line-height: 17px;
        z-index: 10;
        position: absolute;
        bottom: 0;
        right: 0px;
        background: #fff;
      }
    }
    .text-ellipsiss {
      @include ellipsiss(4);
    }
  }
  .all-comment {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -11px;
    span {
      margin-right: 3px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
  }
}
