@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";









































































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.reply-list {
  margin-top: 8px;
  background: #ffffff;
  .reply-title {
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      display: flex;
      span {
        margin-left: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #999999;
      }
    }
    .sort {
      display: flex;
      .sort-item {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
      }
      .sort-item.active {
        color: #2496ff;
      }
    }
  }
  .list-wrap {
    padding: 14px 16px;
    .reply-item {
      margin-bottom: 14px;
      .msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        .user {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 17px;

          .avatar-wrapper {
            position: relative;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            .avatar {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              box-sizing: border-box;
            }
            .auth-icon {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .parent {
            color: #999999;
            margin-left: 6px;
            span {
              color: #333333;
            }
          }
        }
        .timeago {
          font-size: 11px;
          font-weight: 400;
          color: #999999;
          line-height: 15px;
        }
      }
      .content {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 14px;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 14px;
        img {
          margin-left: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}
