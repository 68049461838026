@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";


















































































































































.video-comments {
  background-color: #fff;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    padding-bottom: 0;
    .left {
      height: 25px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      vertical-align: middle;
      span {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
    .sort-btns {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }

    .divider {
      width: 1px;
      height: 10px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 0 8px;
    }
    .active {
      color: #2496ff;
    }
  }
  .comment-card {
    padding: 16px;
  }
  .replys {
    margin-left: 40px;
    ::v-deep .comment-card {
      padding-top: 0;
    }
  }
  .more-reply-btn {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #2496ff;
    line-height: 17px;
    margin-left: 55px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .no-more {
    display: flex;
  }
  .load-more {
    display: flex;
    margin: 20px 20px 0;
    padding-bottom: 20px;
    ::v-deep .load-more-btn {
      width: 90vw;
    }
  }
}
