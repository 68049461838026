@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.related-games {
  width: 100vw;
  overflow-x: auto;
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 28px;
  .related-game-item {
    flex-shrink: 0;
    width: 58px;
    margin-right: 17px;
    .game-icon {
      width: 58px;
      height: 58px;
      overflow: hidden;
      border-radius: 13px;
      margin-bottom: 7px;
    }
    .name {
      font-size: 11px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      @include ellipsiss();
    }
  }
  .last-item {
    font-size: 12px;
    opacity: 0;
  }
}
