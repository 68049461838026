@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































.juhe-game-item {
  width: auto;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  background-color: #fff;
  overflow: hidden;
  .juhe-game-info {
    display: flex;
    align-items: center;
    padding: 16px 14px;
    border-bottom: 1px solid #eee;
    .icon {
      width: 49px;
      height: 49px;
      border-radius: 20px;
      margin-right: 12px;
    }
    .name a {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: #323334;
      line-height: 16px;
      margin-bottom: 6px;
    }
    .tags {
      display: flex;
    }
    .tag a {
      display: block;
      padding: 1px 4px 0;
      margin-right: 8px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      border-radius: 2px;
    }
  }
  .list {
    padding: 16px 14px 0px;
    & > li {
      margin-bottom: 16px;
    }
  }
}
