@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































































































































.detail {
  padding-top: 11px;
  padding-bottom: 15px;
}
.custom-column {
  background: #fff;
  margin: 11px 15px 28px;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
