@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";













































::v-deep .van-popup {
  border-radius: 0;
  background-color: transparent;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}
element::-webkit-scrollbar {
  display: none;
}
