@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





































.map-item {
  padding: 18px 16px 2px;
  border-bottom: 1px solid #f6f6f6;
  .map-title {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    height: 25px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
  }
  .contents {
    display: flex;
    flex-wrap: wrap;
    .content-item {
      height: 30px;
      line-height: 18px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 32px;
      padding: 6px 12px;
      margin: 0 16px 16px 0;
    }
  }
}
