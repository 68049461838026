@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































































.star-container {
  padding: 13px 15px 0;
  margin-bottom: 28px;
  .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    .score {
      width: 62px;
      height: 52px;
      background-image: url("~@/assets/images/icon_pingfen.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: bold;
      color: #ffffff;
      line-height: 23px;
      margin-right: 22px;
    }
    .star-line-item {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      .stars {
        display: flex;
        align-items: center;
        margin-right: 7px;
      }
      .star {
        width: 9px;
        height: 9px;
        display: flex;
        margin-right: 3px;
      }
      .line {
        width: 42.5vw;
        height: 4px;
        background: #dbdcdd;
        border-radius: 2px;
        .line-value {
          height: 4px;
          background: #2496ff;
          border-radius: 2px;
        }
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anli-btn {
      width: 153px;
      height: 37px;
      background: #fafafa;
      border-radius: 19px;
      border: 1px solid #2496ff;
      font-size: 13px;
      font-weight: 400;
      color: #2496ff;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 4px;
        display: inline-block;
        line-height: 20px;
        padding-top: 2px;
      }
    }
    .comment-btn {
      width: 153px;
      height: 37px;
      background: linear-gradient(
        90deg,
        #4bc7ff 0%,
        #2496ff 100%,
        #2496ff 100%
      );
      border-radius: 19px;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 4px;
        display: inline-block;
        line-height: 20px;
        padding-top: 2px;
      }
    }
  }
}
