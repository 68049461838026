@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
























































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.content-result {
  @mixin flex($justify: center) {
    display: flex;
    align-items: center;
    justify-content: $justify;
  }
  .content {
    .header-box {
      width: 100%;
      height: 48px;
      background-color: #fff;
      @include flex(flex-start);
      padding: 0 16px;

      .title {
        margin-right: 8px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 16px;
      }

      .num {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }

    .game {
      background-color: #fff;

      &-item {
        img {
          width: 48px;
          height: 48px;
          border-radius: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .info {
          margin-left: 16px;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 14px;
            margin-bottom: 8px;
          }
          .desc {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }
  }
}
