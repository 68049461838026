@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































.loading-more {
  .main {
    box-sizing: border-box;
    width: 100vw;
    height: 48px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-right: 4px;
    }

    img {
      width: 12px;
      height: 12px;
    }
  }
}
