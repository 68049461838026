@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































.juhe-container {
  overflow: hidden;
  background-color: #fafafa;
}
.juhe-game-list {
  & > div {
    margin: 0 16px 16px;
  }
}
