@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

























.error-page {
  background: #fafafa;
  width: 100%;
  height: 100vh;

  .main {
    height: calc(100vh - 176px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
