@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.image-recommend {
  margin: 0 15px 28px;
  border-radius: 4px;
  overflow: hidden;
}
