@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































.header {
  box-sizing: border-box;
  width: 100vw;
  height: 56px;
  padding: 0 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    img {
      width: 77px;
      height: 18px;
    }
  }

  .right {
    img {
      width: 24px;
      height: 24px;
    }
  }
}
