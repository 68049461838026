
// 蓝
$BaseColorBlue: #2496FF;
// 深蓝
$BaseColorDeepBlue: #2C3A4D;
// 文本-蓝
$TextColorBlue: #1383EB;
// 文本-默认
$TextColor: #CCCCCC;
// 文本-正文
$TextColorContent: #4A4A4A;
// 文本-一级
$TextColor1st: #333333;
// 文本-二级
$TextColor2nd: #666666;
// 文本-三级
$TextColor3rd: #999999;
// 背景灰
$BgColor: #FAFAFA;
// 分隔线
$DividerColor: #EEEEEE;
// 边框
$BorderColor: rgba(0, 0, 0, 0.1);


.border-1px {
    position: relative
}
.border-1px:after {
    content: "";
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
@media (-webkit-min-device-pixel-ratio: 2) {
    .border-1px:after {
        width: 200%;
        height: 200%;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}
  
  
@media (-webkit-min-device-pixel-ratio: 3) {
    .border-1px:after {
      width: 300%;
      height: 300%;
      -webkit-transform: scale(.333);
      transform: scale(.333)
    }
}


@mixin ellipsiss($row: 2) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@mixin ellipsis() {
    text-overflow: ellipsis; //溢出省略号显示
    white-space: nowrap;
    overflow: hidden;
}

html.grayscale {
    filter: grayscale(95%);
    -webkit-filter: grayscale(95%);
    -moz-filter: grayscale(95%);
    -ms-filter: grayscale(95%);
    -o-filter: grayscale(95%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=.95);
    -webkit-filter: saturate(0);
  }