@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































































































































































































































































































































::v-deep .dplayer-played,
::v-deep .dplayer-thumb,
::v-deep .dplayer-volume-bar-inner {
  background: #2496ff !important;
}

.video-page {
  position: relative;
  width: 100vw;
  background: #fafafa;
  .header-row {
    width: 100vw;
    box-sizing: border-box;
    min-width: 320px;
    height: 56px;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    color: $TextColor1st;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    h1 {
      margin: 0 16px;
      line-height: 20px;
      max-width: 220px;
      @include ellipsis();
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
      .search-icon {
        margin-right: 23px;
      }
    }
  }
  .video-wrapper {
    height: 211px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    position: relative;
    .player {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
    .poster {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 3;
    }
  }
  .fixed-bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 10;
  }
}
