@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.page-content {
  font-size: 28px;
  background: #f5f5f5;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 16px;
}
