@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






































































































































































































































.content-search {
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fafafa;
  display: flex;
  flex-direction: column;

  .header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 56px;
  }

  .main {
    flex: 1;
    width: 100%;
    margin-top: 64px;
  }

  .not-found-data {
    height: calc(100vh - 184px);
  }

  footer {
    width: 100%;
    height: 120px;
  }
}
