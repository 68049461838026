@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





































































































































































div.dplayer-wrap {
  font-size: 10px;
}
.ghzs-player {
  width: 100%;
  height: 100%;
  background: #d8d8d8;
  & div.dplayer {
    height: 100%;
    overflow: initial;
  }
}
::v-deep .dplayer-volume {
  display: inline-block !important;
}
