@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































.float-download {
  .main {
    width: 100vw;
    height: 64px;
    background: #fafafa;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    z-index: 10;

    .logo {
      width: 40px;
      height: 40px;
      img {
        @extend .logo;
      }
    }

    .name {
      flex: 1;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 68px;
        height: 16px;
      }

      p {
        margin-top: 4px;
        height: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }

    .download {
      width: 72px;
      height: 32px;
      background: #2496ff;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;

      span {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .close {
      display: flex;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
