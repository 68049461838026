@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































::v-deep .van-divider {
  margin: 13px 0;
  border-color: $DividerColor;
}
.top-container {
  margin: 0 15px 28px;
  padding: 15px 13px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
