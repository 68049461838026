@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
































.juhe-item {
  margin-left: 2px;
  height: 16px;
  margin-bottom: 12px;
}

.li-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a {
  color: #333333;
  max-width: 72vw;
  display: block;
  font-size: 14px;
  line-height: 16px;
  @include ellipsis();
}
.point {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 4px;
  margin-bottom: 1px;
  vertical-align: middle;
}
.time {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.6);
  line-height: 16px;
}
