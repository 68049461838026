@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";










































.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.imgs-swiper {
  width: 100vw;
  overflow-x: auto;
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 28px;
  .img-item {
    flex-shrink: 0;
    width: 91px;
    max-height: 136px;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 7px;
  }
}
