@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































































































































































































































































































































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.c-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  .left {
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $TextColor1st;
    margin-right: 8px;
  }
  .num {
    font-size: 14px;
    font-weight: 400;
    color: $TextColor3rd;
  }
  .right {
    color: $TextColor3rd;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    .divider {
      width: 1px;
      height: 8px;
      background: #eeeeee;
      border-radius: 1px;
      margin: 0 12px;
    }
    .active {
      color: $TextColor2nd;
    }
  }
}
.comment {
  display: flex;
  padding: 16px 16px 0;
  .content {
    flex: 1;
  }
  .avatar-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
    }
    .auth-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .name {
    font-size: 14px;
    font-weight: bold;
    color: $TextColor1st;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  .floor {
    font-size: 11px;
    font-weight: 400;
    color: $TextColor3rd;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .top-tag {
      font-size: 11px;
      font-weight: 400;
      color: #ff9449;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: $TextColorContent;
    line-height: 22px;
  }
  .imgs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    margin-top: 12px;
    .img-item {
      width: calc((100vw - 100px) / 3);
      height: calc((100vw - 100px) / 3);
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-bottom: 8px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 8px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .img-item:nth-child(3n) {
      margin-right: 0;
    }
  }
  .other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    .time {
      font-size: 12px;
      font-weight: 400;
      color: $TextColor3rd;
    }
    .icons {
      display: flex;
    }
  }
  .inner-comment {
    display: flex;
    padding-bottom: 16px;
    .other {
      margin-bottom: 0;
    }
  }
  .vote-wrapper {
    display: flex;
    align-items: center;
    margin-left: 32px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    img {
      margin-right: 4px;
    }
  }
}
.reply-user {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  b {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    line-height: 14px;
  }
  i {
    vertical-align: middle;
    display: inline-block;
  }
}
.more {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  padding-left: 42px;
  margin-top: 4px;
  margin-bottom: 32px;
  i {
    margin-left: 4px;
  }
}
