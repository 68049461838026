@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";




















































































































































.comment-input-component {
  background-color: #fff;
  height: 56px;
  box-sizing: border-box;
  padding: 12px 24px 12px 16px;
  display: flex;
  align-items: center;
  .fake-input {
    flex: 1;
    margin-right: 28px;
    width: 191px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 18px 18px 18px 18px;
    font-size: 14px;
    font-weight: 400;
    color: #cccccc;
    line-height: 32px;
    padding-left: 16px;
  }
  .btns {
    display: flex;

    .btn-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
      }
      span {
        font-size: 10px;
        font-weight: 400;
        color: #666666;
        line-height: 10px;
      }
    }
  }
}
