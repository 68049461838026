@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

































































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.article-container {
  width: 100vw;
  overflow-x: auto;
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 28px;
  .article-top-item,
  .article-item {
    flex-shrink: 0;
    width: 205px;
    height: 168px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 7px;
    position: relative;
    .image {
      width: 205px;
      height: 103px;
      border-radius: 4px 4px 0px 0px;
      overflow: hidden;
    }
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .type {
      font-size: 10px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      padding: 0 11px;
      margin-top: 2px;
    }
    .content {
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top: 9px;
      @include ellipsiss(4);
    }
    .time {
      font-size: 10px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
  }
  .article-item {
    padding: 0 11px;
    .title {
      padding: 0;
      margin-top: 11px;
    }
    .bottom {
      position: absolute;
      bottom: 10px;
      left: 12px;
      right: 12px;
    }
  }
  .more-btn {
    font-size: 12px;
    font-weight: 400;
    color: #2496ff;
    line-height: 18px;
  }
}
