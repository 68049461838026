@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";









































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding-top: 1px;
  }
  .count {
    font-size: 13px;
  }
}
.server-list {
  padding: 0 15px;
  margin-bottom: 28px;
  .server-item {
    height: 17px;
    font-size: 12px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .time-wrap {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
    .time-icon {
      display: flex;
      width: 11px;
      height: 11px;
      margin-right: 4px;
    }
    .note {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
    }
  }
  .server-item:last-child {
    margin-bottom: 0;
  }
}
