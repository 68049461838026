@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































































































































































































::v-deep .swiper-container .swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
::v-deep .swiper-container {
  width: 100%;
  .swiper-slide {
    width: auto;
  }
}

.column-card {
  width: 100%;
  padding: 16px 14px;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      max-width: 218px;
      @include ellipsis();
    }
    .btn {
      display: flex;
      align-items: top;
    }
    .btn-text {
      font-size: 13px;
      font-weight: 400;
      color: #2496ff;
      line-height: 16px;
    }
    .btn-more {
      padding-top: 1px;
    }
  }
  .list {
    display: flex;
    overflow: hidden;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 24px;
      min-width: 64px;
      .time-wrap {
        display: flex;
        align-items: baseline;
        height: 20px;
        .time {
          max-width: 50px;
          height: 15px;
          font-size: 11px;
          font-weight: 600;
          color: #343434;
          line-height: 15px;
          margin-right: 2px;
          @include ellipsis();
        }
      }
      .desc {
        max-width: 66px;
        height: 15px;
        font-size: 11px;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 15px;
        @include ellipsis();
      }
      .time-line {
        height: 36px;
        width: 100%;
        position: relative;
        .dot {
          width: 8px;
          height: 8px;
          background: #cccccc;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 10px;
          transform: translateX(-50%);
          z-index: 2;
        }
        .dot.active {
          background: #2496ff;
        }
        .line {
          width: 134px;
          background: #ebebeb;
          height: 2px;
          position: absolute;
          left: 0;
          top: 13px;
        }
      }
      .image {
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 14px;
        margin-bottom: 8px;
        overflow: hidden;
        font-size: 12px;
      }
      .game-name {
        max-width: 60px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        @include ellipsiss();
      }
    }
  }
}
.auto {
  padding: 0;
  padding-bottom: 8px;
  .title-row {
    padding: 16px 14px 0;
  }
  .image {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 14px;
    margin-bottom: 8px;
    overflow: hidden;
    font-size: 12px;
  }
  .auto-wrapper {
    overflow: hidden;
    .auto-row1,
    .auto-row2 {
      display: flex;
      & > a {
        margin-right: 8px;
      }
    }
    .auto-row2 {
      margin-left: -37px;
    }
  }
}

.loading-comp {
  width: 100%;
  height: auto;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
