@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

















































































































































































































































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.flex {
  display: flex;
  align-items: center;
}

.result-card {
  width: 100%;
  @mixin flex($justify: center) {
    display: flex;
    align-items: center;
    justify-content: $justify;
  }

  &-item {
    box-sizing: border-box;
    padding: 16px 0 24px 0;

    &-userinfo {
      padding: 0 16px;
      @include flex(flex-start);

      .avatar-wrapper {
        position: relative;
        width: 32px;
        height: 32px;
        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          box-sizing: border-box;
        }
        .auth-icon {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .username {
        margin: 0 8px 0 12px;
        height: 12px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #666666;
        line-height: 12px;
      }

      .badge {
        width: 14px;
        height: 14px;
        border-radius: 25%;
      }
    }

    &-text {
      padding: 0 16px;
      .title {
        margin-top: 12px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        .text {
          @include ellipsis();
        }
      }

      .desc {
        margin-top: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        @include ellipsiss();
      }
    }

    // 图片
    &-imgs {
      padding: 0 12px;
      margin-top: 12px;
      @include flex(flex-start);
      overflow-y: hidden;
      .img {
        position: relative;
        margin: 0 3px;
        border-radius: 4px;
        overflow: hidden;
        box-sizing: border-box;
        @include flex;

        .tag-position {
          position: absolute;
          right: 6px;
          bottom: 6px;
        }

        &-tag {
          min-width: 16px;
          min-height: 12px;
          padding: 4px;
          @extend .tag-position;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 2px;
          @include flex;
          z-index: 2;

          span {
            transform: scale(0.86);
            font-size: 10px;
            font-family: NotoSansCJKsc-Medium, NotoSansCJKsc;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        &-tag-img {
          @extend .tag-position;
          width: 28px;
          height: 16px;
          z-index: 2;
        }

        .c-bg {
          width: calc(100% - 1px);
          height: calc(100% - 1px);
          position: relative;
          border-radius: 8px;
          box-sizing: content-box;
        }

        .bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          border-radius: 4px;
        }

        .bg[len="1"] {
          background-position: top center;
        }

        .player {
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50 - 20px);
          width: 40px;
          height: 40px;
          z-index: 2;
        }
      }

      .img[len="1"] {
        // max-height: 300px;
        // max-width: 226px;
        min-height: 149px;
        min-width: 226px;
      }

      .img[len="2"] {
        min-width: 90.6px;
        min-height: 90.6px;
        // max-width: 122px;
        // max-height: 122px;
      }
    }

    &-footer {
      padding: 0 16px;
      margin-top: 24px;
      @include flex(space-between);

      &-time {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        line-height: 12px;
      }

      &-right {
        flex: 1;
        @include flex(flex-end);
        &-comment,
        &-favorite {
          @include flex(flex-start);
          &-img {
            width: 16px;
            height: 16px;
          }
          span {
            margin-left: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        &-comment {
          margin-right: 32px;
        }
      }
    }
  }
}
