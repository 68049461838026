@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.comments-container {
  background: #fafafa;
  .shadow {
    width: 100vw;
    height: 7px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .area-title {
    height: 23px;
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    line-height: 23px;
    padding: 0 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filters {
    display: flex;
    position: relative;
    .options {
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 50;
      font-size: 11px;
      font-weight: 400;
      color: #666666;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 4px 13px 4px rgba(0, 0, 0, 0.05),
        0px 3px 7px 0px rgba(0, 0, 0, 0.08),
        0px 1px 3px -2px rgba(0, 0, 0, 0.12);
      .option-item {
        height: 38px;
      }
      .option-item.active {
        background: rgba(36, 150, 255, 0.1);
      }
    }
    .star-options {
      bottom: -230px;
    }
    .sort-options {
      bottom: -116px;
    }
  }
  .sort {
    font-size: 11px;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-right: 19px;
    img {
      margin-left: 6px;
    }
  }
  .filter {
    font-size: 11px;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    img {
      margin-left: 6px;
    }
  }
  .comments-list {
    padding-bottom: 15px;
  }
  .comment-item {
    margin: 0 15px 7px;
    padding: 15px 13px;
    background: #ffffff;
    border-radius: 4px;

    .attached {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      .content {
        color: #666666;
        @include ellipsiss();
      }
      span {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 17px;
      }
      .more-comments {
        font-size: 11px;
        font-weight: 400;
        color: #2496ff;
        line-height: 15px;
        margin-top: 13px;
      }
    }
  }
}
