@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































.header {
  box-sizing: border-box;
  width: 100vw;
  height: 52px;
  padding: 0 15px;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 63px;
      height: 15px;
    }
    .down-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
      img {
        width: 11px;
        height: 14px;
      }
      span {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: #2496ff;
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 17px;
      height: 17px;
    }
  }
}
