@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";















































































































































































































































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 14px;
  line-height: 37px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 37px;
}
::v-deep .van-divider {
  margin: 0;
  margin-top: -2px;
  border-color: $DividerColor;
}
.contents {
  background: #fff;
  padding-bottom: 50px;
  .c-header {
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .c-sort {
      color: $TextColor3rd;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      .divider {
        width: 1px;
        height: 8px;
        background: #eeeeee;
        border-radius: 1px;
        margin: 0 12px;
      }
      .active {
        color: $TextColor2nd;
      }
    }
  }
  .sections {
    background: #ffffff;
    padding: 12px 52px 12px 16px;
    display: flex;
    max-width: 100vw;
    overflow-x: auto;
    position: relative;
  }

  .section-tag {
    height: 24px;
    white-space: nowrap;
    background: #f5f5f5;
    border-radius: 100px 100px 100px 100px;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-right: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      margin-right: 4px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .icon-more {
    position: absolute;
    right: 0;
    top: 48px;
    z-index: 50;
  }
  .active.section-tag {
    background: #edf5fc;
    color: #1383eb;
  }
  .all-sections {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 50;
    background: rgba(0, 0, 0, 0.2);
    .all-sections-title {
      height: 48px;
      background: #ffffff;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .all-sections-content {
      display: flex;
      flex-wrap: wrap;
      padding: 0 16px;
      background: #ffffff;
      .section-tag {
        margin-bottom: 12px;
      }
    }
  }
  .transparent-mask {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 49;
    background: transparent;
    top: 0;
  }
}
