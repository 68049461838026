@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";












































































.search-header {
  @mixin SHflex($justify: center) {
    display: flex;
    align-items: center;
    justify-content: $justify;
  }

  box-sizing: border-box;
  width: 100vw;
  height: 56px;
  background: #ffffff;
  @include SHflex;
  padding: 16px;

  .back-icon {
    width: 24px;
    height: 24px;
  }

  .search-header-box {
    flex: 1;
    box-sizing: border-box;
    height: 32px;
    background: #f5f5f5;
    border-radius: 32px;
    @include SHflex;
    padding: 8px;
    margin: 0 16px;

    img:nth-child(1) {
      width: 20px;
      height: 20px;
    }

    input {
      width: 0;
      background: #f5f5f5;
      flex: 1;
      height: 32px;
      border: none;
      padding: 0 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 32px;

      &::-webkit-input-placeholder {
        color: $TextColor;
      }

      &:-moz-placeholder {
        color: $TextColor;
      }

      &::-moz-placeholder {
        color: $TextColor;
      }

      &:-ms-input-placeholder {
        color: $TextColor;
      }
    }

    .close {
      width: 16px;
      height: 16px;
    }
  }

  .search-button {
    width: 32px;
    height: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1383eb;
    line-height: 16px;
  }
}
