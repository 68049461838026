@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






























































































































































































































::v-deep .van-tab:first-child {
  margin-left: 7px;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}

::v-deep .van-tab {
  max-width: 60px;
  a {
    color: #999999;
  }
}
::v-deep .van-tab--active a {
  color: #2496ff;
}
::v-deep .van-tabs__wrap {
  height: 42px;
}

.page-content {
  font-size: 28px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  .divider {
    width: 100%;
    height: 7px;
    background: #f5f5f5;
    margin-top: 1px;
  }
}
