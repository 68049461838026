@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.toolkit-page {
  font-size: 28px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}
