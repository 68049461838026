@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































































::v-deep .swiper-pagination-bullets {
  right: 14px !important;
  bottom: 12px !important;
  left: auto !important;
  width: auto !important;
}
::v-deep .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  background: #ffffff;
  opacity: 0.6;
  margin: 0 2px !important;
}
::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
}

.slide-item {
  height: 192px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .swiper-title {
    max-width: 211px;
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    position: absolute;
    left: 14px;
    bottom: 8px;
    z-index: 10;
    @include ellipsis();
  }
}
.mask {
  width: 100%;
  height: 44px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("~@/assets/images/mask.png");
  background-size: 100% 100%;
}
