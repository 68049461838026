@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding-top: 1px;
  }
  .count {
    font-size: 13px;
  }
}
.libao-list {
  margin: 0 15px 28px;
  background: #fff;
  border-radius: 4px;
  padding: 15px 13px;
  .libao-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .libao-msg {
      width: 70vw;
      .name-row {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
        .libao-icon {
          width: 14px;
          height: 14px;
          display: flex;
          margin-right: 4px;
        }
        span {
          padding-top: 1px;
        }
      }
      .content {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        max-width: 70vw;
        @include ellipsis();
      }
    }
    .progress-row {
      position: relative;
      width: 50vw;
      .remain {
        width: 50px;
        text-align: left;
        font-size: 11px;
        font-weight: 400;
        color: #2496ff;
        line-height: 15px;
        position: absolute;
        top: -5px;
        right: -58px;
      }
    }
    .libao-btn {
      width: 52px;
      height: 26px;
      border: 1px solid #2496ff;
      color: #2496ff;
      border-radius: 14px;
      font-size: 11px;
      font-weight: bold;
      line-height: 26px;
      text-align: center;
    }
    .libao-btn.active {
      background: linear-gradient(90deg, #4bc7ff 0%, #2496ff 100%);
      color: #ffffff;
      border: none;
    }
    .libao-btn.disabled {
      border: 1px solid #dddddd;
      color: #dddddd;
      background: none;
    }
  }
}
