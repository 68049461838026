@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";























































































::v-deep .van-divider {
  margin: 8px 0 0 0;
  border-color: $DividerColor;
}
.bbs-list {
  position: relative;
  box-sizing: border-box;
  background: #ffffff;
  width: 100%;
  border-radius: 6px;

  .category-name {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: $TextColor1st;
      margin-right: 8px;
    }
    .num {
      font-size: 14px;
      font-weight: 400;
      color: $TextColor3rd;
    }
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .card {
    width: 100vw;
    background: #ffffff;
    box-sizing: border-box;

    &-main {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8px;

      &-item {
        cursor: pointer;
        width: calc(50vw - 32px);
        display: flex;
        align-items: center;
        margin: 12px 8px;

        &:hover .name {
          color: $TextColorBlue;
        }

        .icon {
          width: 48px;
          height: 48px;
          margin-right: 16px;
          overflow: hidden;
          border-radius: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        &-right {
          flex: 1;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $TextColor1st;
            @extend .ellipsis;
          }
          .desc {
            margin-top: 8px;
            font-size: 12px;
            line-height: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $TextColor3rd;
            @extend .ellipsis;
          }
        }
      }
    }
  }

  .loading-more {
    &:hover {
      cursor: pointer;
    }
    .main {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $TextColor3rd;
        margin-right: 4px;
      }

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
