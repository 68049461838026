@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";

















































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.game-comment-detail {
  background: #f5f5f5;
  .detail-header {
    background: #fff;
    font-size: 17px;
    font-weight: bold;
    color: #000000;
    line-height: 24px;
    padding: 10px 0;
    text-align: center;
    position: relative;
    margin-bottom: 8px;
    .back-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      left: 17px;
    }
  }
  .game {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 16px;
    .left {
      display: flex;
      align-items: center;
    }
    .game-icon {
      width: 64px;
      height: 64px;
      border-radius: 14px;
      overflow: hidden;
      margin-right: 12px;
    }
    h1 {
      font-size: 14px;
      font-weight: bold;
      color: #323334;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .other {
      font-size: 10px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
    .download-btn {
      width: 56px;
      height: 28px;
      background: linear-gradient(
        90deg,
        #4bc7ff 0%,
        #2496ff 100%,
        #2496ff 100%
      );
      border-radius: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
    }
  }
  .comment-card-wrap {
    padding: 12px 16px;
    background: #fff;
  }
}
