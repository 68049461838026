@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























































































































.map-page {
  .title {
    padding: 13px 17px 10px;
    text-align: center;
    height: 24px;
    font-size: 17px;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
    background: #fff;
    .back-icon {
      width: 9px;
      height: 17px;
      display: flex;
      position: absolute;
      left: 17px;
      top: 15px;
      transform: rotate(180deg);
    }
  }
  .divider {
    height: 8px;
    background: #f5f5f5;
  }
  .fixeds {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .content {
    padding-top: 55px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
  }
}
