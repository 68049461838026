@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";






















































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
::v-deep .van-field__clear {
  color: rgb(220, 220, 220);
}
::v-deep .van-cell:not(:last-child)::after {
  border: none;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 14px;
}
::v-deep .van-tab--active {
  color: #2496ff;
}
::v-deep .van-tabs__wrap {
  width: 120px;
  height: 42px;
}
::v-deep .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}
* {
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
}
.tag-container {
  width: 100vw;
  background-color: #f5f5f5;
  overflow-x: hidden;
  .tag-header {
    padding: 15px 17px 10px 17px;
    justify-content: space-between;
    background-color: #fff;
    & span {
      margin-left: 14px;
      height: 24px;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 24px;
    }
    & .search {
      font-weight: bold;
    }
  }

  .tabs-box {
    padding: 0 15px;
    background-color: #fff;
    margin-bottom: 10px;
  }

  .search-list {
    padding: 15px 15px 0 15px;
    background-color: #fff;
  }
  .empty-area {
    position: absolute;
    top: calc(50vh - 102px);
    left: 0;
    right: 0;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
