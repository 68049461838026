@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";































































































































































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.c-header {
  display: flex;
  align-items: center;
  padding: 16px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $TextColor1st;
    margin-right: 8px;
  }
  .num {
    font-size: 14px;
    font-weight: 400;
    color: $TextColor3rd;
  }
}
.comment {
  display: flex;
  padding: 16px 16px 0;
  .content {
    flex: 1;
  }
  .avatar-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
    }
    .auth-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .name {
    font-size: 14px;
    font-weight: bold;
    color: $TextColor1st;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  .floor {
    font-size: 11px;
    font-weight: 400;
    color: $TextColor3rd;
    margin-bottom: 8px;
    display: flex;
    .top-tag {
      font-size: 11px;
      font-weight: 400;
      color: #ff9449;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: $TextColorContent;
    line-height: 22px;
  }
  .other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    .time {
      font-size: 12px;
      font-weight: 400;
      color: $TextColor3rd;
    }
    .icons {
      display: flex;
    }
  }

  .vote-wrapper {
    display: flex;
    align-items: center;
    margin-left: 32px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    img {
      margin-right: 4px;
    }
  }
}
.parent-comment {
  padding: 12px 12px 8px;
  border-radius: 4px;
  background: #f5f5f5;
  margin-bottom: 12px;
  .parent {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 4px;
    line-height: 18px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }
}

.more {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  padding-left: 42px;
  margin-top: 4px;
  margin-bottom: 32px;
  i {
    margin-left: 4px;
  }
}
