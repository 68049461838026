:root {
  --pixel-border-dpr: 1;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  :root {
    --pixel-border-dpr: 2;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  :root {
    --pixel-border-dpr: 2;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 4), (min-resolution: 4dppx) {
  :root {
    --pixel-border-dpr: 2;
  }
}

[pixel-border],
[pixel-border="true"] {
  position: relative;
  border-width: 0;
  box-sizing: border-box;
}

[pixel-border]::before,
[pixel-border="true"]::before {
  --scale: calc(1 / var(--pixel-border-dpr));
  --size: calc(var(--pixel-border-dpr) * 100%);
  content: "";
  pointer-events: none;
  display: block;
  box-sizing: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  border: inherit;
  border-width: 1px;
  border-image: inherit;
  border-radius: inherit;
  border-color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) scale(var(--scale), var(--scale));
}
