@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































































































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
.comment-top {
  display: flex;
  .avatar-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 6px;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
    }
    .auth-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .name {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
  }
  .other {
    display: flex;
  }
  .stars {
    display: flex;
    .star {
      width: 12px;
      height: 12px;
      margin-right: 2px;
      display: flex;
    }
  }
  .timeago {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-bottom: 11px;
  }
}

.text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  display: block;
  position: relative;
  .all-btn {
    font-size: 12px;
    font-weight: 400;
    color: #2496ff;
    line-height: 17px;
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0px;
    background: #fff;
  }
}

.text-ellipsiss {
  @include ellipsiss(4);
}

.comment-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 400;
  color: #989898;
  line-height: 14px;
  margin-top: 15px;
  .left,
  .right,
  .device,
  .action {
    display: flex;
    align-items: center;
    img {
      margin-right: 4px;
    }
  }
  .device-name {
    padding-top: 2px;
    width: 46px;
    @include ellipsis();
  }
}
