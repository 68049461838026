@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";









































































































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-tab:first-child {
  margin-left: 10px;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}

::v-deep .van-tab {
  max-width: 40px;
  padding: 0;
  margin: 0 10px;
  a {
    color: #999999;
  }
}
::v-deep .van-tab--active {
  color: #2496ff;
  font-weight: bold;
}
::v-deep .van-tabs__wrap {
  height: 42px;
}

.home-bbs {
  font-size: 28px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  ::v-deep .result-card-item {
    padding: 16px 0 22px 0;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .header-fixed {
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 56px;
  }

  .hot-forum {
    width: 100vw;
    height: 100px;
    background: #fafafa;

    &-main {
      display: flex;
      align-items: center;
      width: calc(100vw - 32px);
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 8px 16px 0 16px;
      justify-content: space-between;

      &-item {
        text-align: center;
        .img {
          width: 48px;
          height: 48px;
          border-radius: 12px;
        }

        p {
          width: 48px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
          margin-top: 8px;
        }
      }
    }
  }

  .card {
    width: 100vw;
    background: #ffffff;
    box-sizing: border-box;

    &-main {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8px;

      &-item {
        width: calc(50vw - 32px);
        display: flex;
        align-items: center;
        margin: 12px 8px;

        .img {
          width: 48px;
          height: 48px;
          border-radius: 12px;
          margin-right: 12px;
        }

        .name {
          flex: 1;
          p:nth-child(1) {
            line-height: 16px;
            height: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            width: 26vw;
            @extend .ellipsis;
          }
          p:nth-child(2) {
            margin-top: 6px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            width: 26vw;
            color: #999999;
            @extend .ellipsis;
          }
        }
      }
    }
  }
}
