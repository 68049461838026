@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";








































































::v-deep .van-divider {
  width: 100%;
  margin: 0;
  border-color: $DividerColor;
}
@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.search-item {
  display: flex;
  align-items: center;
  padding: 16px;
  padding-right: 0;
  box-sizing: border-box;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    width: calc(100% - 92px);
    display: flex;
    align-items: center;
    margin-left: 11px;
    position: relative;
  }

  .game-icon {
    width: 64px;
    height: 64px;
    border-radius: 13px;
    overflow: hidden;
  }
  .game-title {
    max-width: 170px;
    height: 19px;
    font-size: 13px;
    font-weight: bold;
    color: #323334;
    line-height: 19px;
    margin-bottom: 6px;
    @include ellipsis();
  }
  .game-other {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }
  .score {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    color: #2496ff;
    line-height: normal;
    span {
      padding-top: 1px;
      margin-left: 4px;
      margin-right: 8px;
    }
  }
  .desc {
    max-width: 140px;
    height: 13px;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    line-height: 13px;
    @include ellipsis();
    padding-top: 1px;
  }
  .tags {
    display: flex;
    align-items: center;
  }
  .tag {
    padding: 0 4px;
    height: 13px;
    background: #f4f5f6;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 400;
    color: #98999a;
    line-height: 13px;
    text-align: center;
    margin-right: 7px;
    background: #f4f5f6;
    white-space: nowrap;
    padding-top: 2px;
  }
  .btn {
    padding: 0 15px;
    height: 26px;
    background: linear-gradient(90deg, #4bc7ff 0%, #2496ff 100%, #2496ff 100%);
    border-radius: 13px;
    font-size: 11px;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    margin-left: 26px;
    white-space: nowrap;
    position: absolute;
    right: 0;
    top: 17px;
  }
  .divider {
    position: absolute;
    right: 0;
    bottom: -16px;
  }
}

.idx {
  min-width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #f5f5f5;
  font-size: 10px;
  font-weight: 500;
  color: #999999;
  line-height: 16px;
  background: #f5f5f5;
  text-align: center;
  margin-right: 12px;
}
.hot {
  background: #f5585c;
  border: 1px solid #f5585c;
  color: #ffffff;
}
.empty-area {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}
