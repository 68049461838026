@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";
















































































































































































































































































































































































































































































































@mixin ellipsiss($row: 2) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}

.container {
  background: $BgColor;

  .nav {
    height: 56px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .nl {
      display: flex;
      align-items: center;
      color: $TextColor1st;
      .name {
        margin-left: 16px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .header-area {
    max-height: 197px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    position: relative;
    overflow: hidden;
    .bg-box {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      filter: blur(10px);
      overflow: hidden;
      .icon-bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .inner-wrap {
      background-color: rgba(0, 0, 0, 0.4);
      filter: blur(0);
      padding: 16px 16px 14px;
    }
    .h-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
    }
    .h-game {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .h-l {
        display: flex;
        align-items: center;
      }
      .h-game-icon {
        width: 66px;
        height: 66px;
        margin-right: 16px;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        box-sizing: content-box;
        div {
          width: calc(100% - 1px);
          height: calc(100% - 1px);
          border-radius: 28px;
          position: absolute;
          top: 0.5px;
          left: 0.5px;
          right: 0.5px;
          bottom: 0.5px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 64px;
            height: 64px;
          }
        }
      }
      h1 {
        max-width: 49vw;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 6px;
        @include ellipsis();
      }
      .h-game-desc {
        max-width: 49vw;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        @include ellipsis();
      }
      .focus-btn {
        width: 56px;
        height: 28px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 28px;
      }
    }
    .h-other {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .member {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      span {
        margin-right: 4px;
        margin-left: 8px;
      }
    }
    .avatar-list {
      display: flex;
      margin-left: 6px;
    }
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      box-sizing: border-box;
      margin-left: -6px;
      &:nth-child(1) {
        z-index: 3;
      }
      &:nth-child(2) {
        z-index: 2;
      }
    }
    .zone-btn {
      width: 98px;
      height: 32px;
      display: flex;
    }
  }

  .no-bg {
    background: #fff;
    .inner-wrap {
      background-color: transparent;
    }
    .h-game {
      h1 {
        color: $TextColor1st;
      }
      .h-game-desc {
        color: $TextColor3rd;
      }
      .focus-btn {
        border: 1px solid $DividerColor;
        background: #ffffff;
        color: $TextColor2nd;
      }
    }
    .member {
      color: $TextColor2nd;
    }
  }
  .tops {
    padding: 8px 0;
    position: relative;
    background: #fff;
    margin-bottom: 8px;
    .top-item {
      height: 36px;
      padding: 0 44px 0 16px;
      display: flex;
      align-items: center;
      h2 {
        font-size: 14px;
        font-weight: 400;
        color: $TextColor1st;
        margin-left: 8px;
        @include ellipsis();
      }
    }
    .t-arrow {
      width: 40px;
      height: 36px;
      position: absolute;
      right: 4px;
      bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default {
      transition: all 0.2s;
    }

    .rotate {
      transform: rotate(-180deg);
      transition: all 0.2s;
    }

    .tops-fade-enter-active {
      transition: all 0.2s ease-in-out;
    }
    .tops-fade-leave-active {
      max-height: 232px;
    }
    .tops-fade-enter,
    .tops-fade-leave-to {
      max-height: 124px;
    }
  }
}
.fixed-bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}
