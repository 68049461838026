@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";





























































































@mixin ellipsis() {
  text-overflow: ellipsis; //溢出省略号显示
  white-space: nowrap;
  overflow: hidden;
}
.title {
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  position: relative;
  margin-bottom: 16px;
  .clear {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    span {
      margin-left: 4px;
    }
  }
}
.history-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .history-item {
    padding: 0 11px;
    height: 25px;
    background: #f5f5f5;
    border-radius: 13px;
    font-size: 11px;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
    text-align: center;
    margin-right: 7px;
    margin-bottom: 7px;
  }
}
.hot-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  .hot-item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .name {
      max-width: 53%;
      @include ellipsis();
    }

    &:nth-child(1) {
      color: #ff4147;
    }
    &:nth-child(2) {
      color: #ff9933;
    }
    &:nth-child(3) {
      color: #ffc247;
    }
  }
  .icon-wrap {
    width: 28px;
    height: 28px;
    border-radius: 7px;
    overflow: hidden;
    margin-left: 14px;
    margin-right: 7px;
  }
}
