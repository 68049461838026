@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";



















































.area-title {
  height: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 23px;
  padding: 0 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-btn {
    font-size: 11px;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
  }
  .count {
    font-size: 13px;
  }
}
.update-text {
  padding: 0 15px;
  margin-bottom: 28px;
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  position: relative;
  .all-btn {
    font-size: 12px;
    font-weight: 400;
    color: #2496ff;
    line-height: 17px;
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 18px;
  }
}
.ellipsis-after:after {
  content: "...";
  position: absolute;
  bottom: -1px;
  right: 0;
  padding-right: 50px;
  padding-left: 0px;
  background: linear-gradient(to right, transparent, #f6f6f6 0%);
}
