@import "@/styles/variables.scss";@import "@/styles/pixel-border.scss";














































































































































::v-deep .van-divider {
  margin: 0;
  border-color: $DividerColor;
}
::v-deep .van-hairline--top-bottom::after {
  border-color: transparent;
}
::v-deep .van-tabs {
  margin-left: -5px;
}
::v-deep .van-tab {
  margin-right: 21px;
}
::v-deep .van-divider {
  margin: 0;
  margin-top: -2px;
  border-color: $DividerColor;
}
::v-deep .van-tabs__line {
  width: 20px;
}
.jinghua-list {
  .result-content {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  .game-item {
    cursor: pointer;
    background-color: #fff;
    display: block;
  }
}
